<template>
  <b-navbar
    class="navbar"
    :fixed="windowWidth > 767 ? 'top' : 'bottom'"
    :style="dynamicStyles"
    toggleable="lg"
  >
  <mobile-navs
    v-if="windowWidth < 991"
    @filter-click="handleFilterClick"
    @client-click="toggleSideBar"
    ref="mobileNavs"/>
    <b-container fluid>
      <b-navbar-brand to="/" class="md-navbar-brand d-none d-lg-block">
        <img
          class="logo-header"
          src="@/assets/images/logo/EasyBase_logo_4.png"
          alt="EasyBase"
        />
      </b-navbar-brand>
      <e-sidebar
        v-if="isFiltersSidebarEnabled"
        v-model="filtersSidebar"
        id='sidebar-1'
        v-click-outside="outsideToggleFiltersSidebar"
        shadow>

        <div class="filters-content">
          <Filters
            @close-filter-sidebar="toggleFiltersSidebar()"
          />
        </div>
      </e-sidebar>

      <!-- Easycoins -->
      <div style="padding-right: 20px" v-if="windowWidth > 767">
        <span style="font-size: 16px; color: #27a39f"
          >{{ easycoins }} Easycoins</span
        >
      </div>

      <!-- Loader -->
      <div class="loader" v-if="loaderObject">
        <div class="dot dot1"></div>
        <div class="dot dot2"></div>
        <div class="dot dot3"></div>
        <div class="dot dot4"></div>
      </div>
      <!-- Footer title for mobile | menu -->
      <div class="md-navbar-brand d-block d-lg-none">{{ pageTitle }}</div>

      <div class="res-navbar-wrapper" ref="resNavbarBackdropFilter"></div>

      <!-- Header for desktop -->
      <div
        class="res-navbar"
        ref="resNavbar"
        v-if="!this.$route.meta.menu"
      >
        <div class="res-navbar__wrap navbar-header-mobile">
          <div class="res-navbar__logo">
           <img
             src="@/assets/images/logo/EasyBase_logo_2.png"
             alt="EasyBase"
           />
            <!-- <img src="@/assets/images/logo/new-year-logo.png" alt="EasyBase" /> -->
          </div>
          <div
            class="nav-link-border nav-item d-lg-none"
            style="margin-bottom: auto; margin-top: auto"
          >
            <span style="font-size: 16px; color: #27a39f"
              >{{ userMe.easy_coins }} Easycoins</span
            >
          </div>
          <ul class="ml-md-auto navbar-nav align-content-center">
            <li class="nav-link-border nav-item">
              <router-link active-class="active" to="/base" class="nav-link">
                <icon name="cloud_icon" class="filter-item-icon d-sm-none" />
                <span>База</span>
              </router-link>
            </li>
            <li class="nav-link-border nav-item mob-none" v-if="windowWidth > 767">
              <router-link active-class="active" to="/archive" class="nav-link">
                <icon name="cloud_icon" class="filter-item-icon d-sm-none" />
                <span>Архів</span>
              </router-link>
            </li>

            <li class="nav-link-border nav-item d-lg-none">
              <router-link active-class="active" to="/base" class="nav-link">
                <icon name="cloud_icon" class="filter-item-icon d-sm-none" />
                <span>База</span>
              </router-link>
            </li>
            <li class="nav-link-border nav-item d-lg-none">
              <router-link active-class="active" to="/archive" class="nav-link">
                <icon name="archive_icon" class="filter-item-icon d-sm-none" />
                <span>Архів</span>
              </router-link>
            </li>
            <li class="nav-link-border nav-item d-lg-none">
              <router-link
                active-class="active"
                to="/profile#payments"
                class="nav-link"
              >
                <icon name="pay_icon" class="filter-item-icon d-sm-none" />
                <span>Оплата</span>
              </router-link>
            </li>

            <li class="nav-link-border nav-item term-block">
              <router-link active-class="active" to="/search" class="nav-link">
                <icon name="search" class="filter-item-icon d-sm-none" />
                <span>Мої пошуки</span>
              </router-link>
            </li>
            <li class="nav-link-border nav-item">
              <router-link active-class="active" to="/objects" class="nav-link">
                <icon name="builds_icon" class="filter-item-icon d-sm-none" />
                <span>Мої об'єкти</span>
              </router-link>
            </li>
            <li class="nav-link-border nav-item">
              <router-link active-class="active" to="/profile" class="nav-link">
                <icon name="profile_icon" class="filter-item-icon d-sm-none" />
                <span>Мої дані</span>
              </router-link>
            </li>
            <li class="nav-link-border nav-item">
              <router-link active-class="active" to="/stock" class="nav-link">
                <icon name="archive_icon" class="filter-item-icon d-sm-none" />
                <span>EasyCoin</span>
              </router-link>
            </li>
            <li class="nav-link-border nav-item" data-text="нове">
              <router-link active-class="active" to="/useful" class="nav-link">
                <span>Kopисне</span>
              </router-link>
            </li>
            <li class="nav-link-spr d-lg-inline-block">
              <a
                class="nav-link"
                target="_blank"
                href="https://invite.viber.com/?g2=AQAK9IpHpaTlGU%2BoaVtqM8PR7n2OiVrVC69g1LLx7sbtzYdN8w9hYEdqUo7%2BR%2BNd"
              >
                <img
                  src="@/assets/icons/viber-icon.svg"
                  width="25"
                  height="25"
                />
              </a>
            </li>
            <li class="nav-link-spr d-lg-inline-block">
              <a
                class="nav-link"
                target="_blank"
                href="https://instagram.com/easybase.dnepr"
              >
                <img
                  src="@/assets/icons/instagram-simple.svg"
                  width="25"
                  height="25"
                />
              </a>
            </li>
            <li class="nav-item">
              <notification-tooltip
                v-if="windowWidth > 767"
                @open-modal="openModal($event)"
              />
            </li>
            <li class="nav-item dropdown dm-nav-dropdown d-none d-lg-block">
              <router-link
                active-class="active"
                to="/profile"
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
              >
                <img src="@/assets/images/login.png" />
              </router-link>
              <div
                class="dropdown-menu dm-dropdown-menu dm-dropdown-menu-left"
                aria-labelledby="navbarDropdown"
              >
                <router-link to="/logout" class="dropdown-item"
                  >Вийти</router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="res-navbar res-filter d-lg-none" ref="resFilter">
        <div class="res-navbar__wrap" v-if="windowWidth < 991">
          <div
            class="clients"
            v-if="$route.name === 'objects'"
            v-click-outside="outsideToggleSidebar"
          >
            <FilterObjects />
          </div>

          <div
            class="clients"
            v-else-if="shouldShowClientsFilters"
            v-click-outside="outsideToggleSidebar"
          >
            <ClientFilter />
          </div>
        </div>
      </div>
    </b-container>
    <popup-referral />

    <notification-popup :notification="notification" v-model="model" />
  </b-navbar>
</template>

<script>
import ESidebar from '../../../elements/e-sidebar';
import ClientFilter from '../../clients/ClientFilter';
const ALLOWED_ROUTE_FOR_CLIENTS_FILTER = ['search', 'client']
import { mapState } from 'vuex';
// eslint-disable-next-line import/no-unresolved
import api from '@/api';
// eslint-disable-next-line import/no-unresolved
import popupReferral from '@/components/popups/popup-referral';
import notificationTooltip from './components/notification-tooltip';
import notificationPopup from './components/notification-popup';

export default {
  name: 'Navbar',
  components: {
    ESidebar,
    notificationPopup,
    notificationTooltip,
    ClientFilter,
    Filters: () => import ('../../app/Filters'),
    popupReferral,
    FilterObjects: () => import ('../../app/filters/filter-my-objects/FilterObjects'),
    MobileNavs: () => import ('../../app/mobile-navs')
},
  data() {
    return {
      windowWidth: 0,
      isOpenMenuSideBar: false,
      easycoins: 0,
      model: false,
      show: false,
      notification: false,
      sidebarIsOpen: false,
      filtersSidebar: false,
      filtersObjectSidebar: false,
      filtersClientSidebar: false,
    };
  },
  computed: {
    ...mapState({
      loaderObject: (state) => state.main.loaderObject,
      userMe: (state) => state.profile.userMe
    }),
    dynamicStyles() {
      return this.isMobileScreen ? { height: '0', padding: '0' } : {};
    },
    shouldShowClientsFilters() {
      return ALLOWED_ROUTE_FOR_CLIENTS_FILTER.includes(this.$route.name);
    },
    isMobileScreen() {
      return this.windowWidth < 991
    },
    pageTitle: ({ $route: { meta } }) => meta.title,
    isFiltersSidebarEnabled() {
      return ['home', 'archive'].includes(this.$route.name);
    },
  },
  watch: {
    '$route'() {
      this.sidebarIsOpen = false;
      this.filtersSidebar = false;
      this.$refs.resNavbarBackdropFilter?.classList?.remove('show');
      this.$refs.resFilter?.classList?.remove('show');
    }
  },
  methods: {
    openModal(notification) {
      this.model = true;
      this.notification = notification;
    },
    toggleSideBar() {
      this.$refs.resNavbarBackdropFilter?.classList?.toggle('show');
      this.$refs.resFilter.classList.toggle('show');
      this.sidebarIsOpen = !this.sidebarIsOpen;
    },

    handleFilterClick(e) {
      e.stopPropagation();
      this.toggleFiltersSidebar()
    },

    toggleFiltersSidebar() {
      this.filtersSidebar = !this.filtersSidebar
      this.$refs.resFilter?.classList?.toggle('show');
    },

    outsideToggleSidebar(e) {
      if (this.sidebarIsOpen) {
        if (this.$refs.mobileNavs && this.$refs.mobileNavs.$el.contains(e.target)) {
          return;
        }

        this.toggleSideBar();
      }
    },

    outsideToggleFiltersSidebar(e) {
      if (this.filtersSidebar) {
        if (this.$refs.mobileNavs && this.$refs.mobileNavs.$el.contains(e.target)) {
          return;
        }

        this.toggleFiltersSidebar();
      }
    }
  },

  created() {
    this.windowWidth = window.innerWidth;

    api.getEasycoins().then((res) => {
      if (res.data && res.data.easycoins) {
        this.easycoins = res.data.easycoins;
      }
    });
  }
};
</script>
