<template>
  <div>
    <b-tooltip
      v-if="apartment.is_archive === '1' && windowWidth > 991"
      :target="`tooltip-target-${apartment.id}`"
      triggers="hover"
    >
      Объект продан, через 3 дня после подтверждения будет перенесён в
      архив!
    </b-tooltip>
    <div
      class="d-flex flex-column flex-lg-row card-block"
      :class="apartment.is_archive === '1' ? 'card-block--arhived' : ''"
      :id="`tooltip-target-${apartment.id}`"
    >
      <div>
        <div
          class="catalog-item item d-none d-lg-block"
          @click="showModal(apartment.id, $event)"
        >
          <div class="catalog-item-photo item-photo">
            <div
              class="hp-bg-container"
              :style="{
                backgroundImage: `url(${
                  apartment.images.length > 0
                    ? apartment.images[0].img_obj
                    : require('@/assets/images/no-image.gif')
                })`
              }"
            >
              <img
                v-if="apartment.status === 'created'"
                src="@/assets/images/added-by-user.png"
                alt="No moderator"
                class="img-no-moderator"
                title="Додано користувачем без перевірки модераторами"
                :id="`tooltip-target-added-by-user-${apartment.id}`"
              />
              <b-tooltip :target="`tooltip-target-added-by-user-${apartment.id}`" triggers="hover" placement="bottom" v-if="windowWidth > 991">
                Додано користувачем без перевірки модераторами
              </b-tooltip>
              <img
                v-if="apartment.status === 'unmoderated'"
                src="@/assets/images/no_moderator.png"
                alt="No moderator"
                class="img-no-moderator"
                title="Об'єкт додано без модерації"
                :id="`tooltip-target-no-moderator-${apartment.id}`"
              />
              <b-tooltip :target="`tooltip-target-no-moderator-${apartment.id}`" triggers="hover" placement="bottom" v-if="windowWidth > 991">
                Об'єкт додано без модерації
              </b-tooltip>
              <img
                v-if="apartment.status === 'draft'"
                src="@/assets/images/icon_for_my_object.png"
                alt="No moderator"
                class="img-no-moderator"
                title="Об'єкт додано для особистого користування"
                :id="`tooltip-target-icon-for-my-object-${apartment.id}`"
              />
              <b-tooltip :target="`tooltip-target-icon-for-my-object-${apartment.id}`" triggers="hover" placement="bottom" v-if="windowWidth > 991">
                Об'єкт додано для особистого користування
              </b-tooltip>
              <!--  -->
              <img
                v-if="apartment.is_cashless_payment_available"
                src="@/assets/images/cashless_payments.png"
                alt="No moderator"
                class="img-no-moderator"
                title="Власник готовий проводити угоду в безготівковій формі, що може підпадати під можливість розрахунку єОселя, 280 стаття та інші"
                :id="`tooltip-target-used-for-cashless-payments-${apartment.id}`"
              />
              <b-tooltip :target="`tooltip-target-used-for-cashless-payments-${apartment.id}`" triggers="hover" placement="bottom" v-if="windowWidth > 991">
                Можлива безготівкова оплата, яка може бути використана для угод іпотеки, програми "єОселя", угоди за 280 постановою та інші операції.
              </b-tooltip>
              <!--  -->
              <img
                v-if="apartment.is_used_for_advertising"
                src="@/assets/images/used_for_advertising.png"
                alt="No moderator"
                class="img-no-moderator"
                title="Фото об'єкта можна використовувати на рекламних майданчиках"
                :id="`tooltip-target-used-for-advertising-${apartment.id}`"
              />
              <b-tooltip :target="`tooltip-target-used-for-advertising-${apartment.id}`" triggers="hover" placement="bottom" v-if="windowWidth > 991">
                Фото об'єкта можна використовувати на рекламних майданчиках
              </b-tooltip>
              <img
                v-if="apartment.is_seller_pays_the_commission"
                src="@/assets/images/seller_pays_the_commission.png"
                alt="No moderator"
                class="img-no-moderator"
                title="Продавець оплачує комісію"
                :id="`tooltip-target-seller-pays-${apartment.id}`"
              />
              <b-tooltip :target="`tooltip-target-seller-pays-${apartment.id}`" triggers="hover" placement="bottom" v-if="windowWidth > 991">
                Продавець оплачує комісію
              </b-tooltip>
              <!-- Is children allowed -->
              <img
                v-if="apartment.is_children_allowed"
                src="@/assets/images/is_children_allowed.png"
                alt="Allow children"
                class="img-no-moderator"
                title="Можна з дітьми"
                :id="`tooltip-target-is-children-allowed-${apartment.id}`"
              />
              <b-tooltip :target="`tooltip-target-is-children-allowed-${apartment.id}`" triggers="hover" placement="bottom" v-if="windowWidth > 991">
                Можна з дітьми
              </b-tooltip>
              <!-- Is foreigners allowed -->
              <img
                v-if="apartment.is_foreigners_allowed"
                src="@/assets/images/is_foreigners_allowed.png"
                alt="Allow foreigners"
                class="img-no-moderator"
                title="Беруть іноземців"
                :id="`tooltip-target-is-foreigners-allowed-${apartment.id}`"
              />
              <b-tooltip :target="`tooltip-target-is-foreigners-allowed-${apartment.id}`" triggers="hover" placement="bottom" v-if="windowWidth > 991">
                Беруть іноземців
              </b-tooltip>
              <!-- Is pets allowed -->
              <img
                v-if="apartment.is_pets_allowed"
                src="@/assets/images/is_pets_allowed.png"
                alt="Allow is_pets"
                class="img-no-moderator"
                title="Можна з тваринами"
                :id="`tooltip-target-is-pets-allowed-${apartment.id}`"
              />
              <b-tooltip :target="`tooltip-target-is-pets-allowed-${apartment.id}`" triggers="hover" placement="bottom" v-if="windowWidth > 991">
                Можна з тваринами
              </b-tooltip>
            </div>
          </div>
          <ul class="catalog-item-prop-container item-prop-container">
            <li
              class="catalog-item-prop text-grey item-prop item-title firstLine-card"
              v-html="getTitle()"
            ></li>
            <li class="catalog-item-prop item-prop text-grey">
              <template v-if="apartment.type === 'commerce'">
                <span style="padding-right: 5px;">{{ apartment.rooms ? `${apartment.rooms}к` : '' }}</span>
                <div
                    v-if="short_commerce_types[apartment.apt_type]"
                  >
                    {{ short_commerce_types[apartment.apt_type] }}
                    <svg
                      class="icon question-icon ml-2"
                      :id="`tooltip-commerce-types-${apartment.id}`"
                    >
                      <use xlink:href="@/assets/icons/question.svg#question"></use>
                    </svg>
                    <b-tooltip :target="`tooltip-commerce-types-${apartment.id}`" triggers="hover" placement="bottom">
                      {{ commerce_types[apartment.apt_type] }}
                    </b-tooltip>
                  </div>
                  <div v-else >{{ commerce_types[apartment.apt_type] }}</div>
              </template>
              <template v-else>
                {{ material_labels[apartment.material_type] }}
                {{ apartment.is_angled ? 'Кутова /' : '' }}
                {{
                  apartment.type === 'house' ? house_type[apartment.apt_type] : ''
                }}
                {{ apartment.rooms ? `${apartment.rooms}к` : '' }}
                {{ apartment.apt_type === 'new_building' ? '/нов' : '' }}
              </template>
            </li>
            <li class="catalog-item-prop item-prop text-grey">
              {{ apartment.floor ? `${apartment.floor}/` : '' }}
              {{ apartment.floors }}
              <template v-if="apartment.type === 'house'">Поверховість</template>
              <template v-if="apartment.type === 'commerce'">
                {{ material_labels[apartment.material_type] }}
              </template>
            </li>
            <li
              class="catalog-item-prop item-prop text-grey"
              v-html="square"
            ></li>
            <li class="catalog-item-prop item-prop text-grey">
              {{ repairItem }}
            </li>
            <li class="catalog-item-prop item-prop text-grey">
              <span class="old-price">{{ apartment.price_old }}</span>
              <span>{{ getPrice() }}</span>
              <svg @click="e => e.stopPropagation()" class="icon question-icon ml-2" :id="`tooltip-target-price-desktop-${apartment.id}`">
                <use xlink:href="@/assets/icons/pay_icon.svg#Capa_1"></use>
              </svg>
            <b-tooltip :target="`tooltip-target-price-desktop-${apartment.id}`" triggers="hover" placement="bottom" v-if="windowWidth > 991">
              {{ getPricePerMeter() }}
            </b-tooltip>
            </li>
            <li class="catalog-item-prop item-prop text-grey">
              <a :href="`tel:${getPhone(apartment.phone[0])}`">{{ getPhone(apartment.phone[0]) }}</a>
              <union-list
                v-if="apartment.unions.length"
                :list="apartment.unions"
              />
            </li>
            <li
              class="catalog-item-prop item-prop catalog-item-prop-label"
              :id="`popover-target-${apartment.id}`"
            >
              <div
                class="btn-group-toggle form-checkbox-b"
                data-toggle="buttons"
                v-for="(item, index) in labels_display"
                :key="index + 'i'"
              >
                <div class="btn-group-toggle form-checkbox-b">
                  <label>
                    <input
                      v-show="false"
                      type="checkbox"
                      name="labels[]"
                      v-model="labels"
                      :value="item.key"
                    />
                    <icon
                      :name="`menu_inner_icon${index}`"
                      class="btn-menu-inner-icon"
                    />
                  </label>
                </div>
              </div>
              <b-popover
                :target="`popover-target-${apartment.id}`"
                triggers="hover"
                placement="top"
                custom-class="filter-modal dropdown-menu"
              >
                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div
                      class="btn-group-toggle form-checkbox-b"
                      data-toggle="buttons"
                      v-for="(item, index) in labels_display"
                      :key="index"
                    >
                      <label :class="`btn`">
                        <input
                          type="checkbox"
                          name="labels[]"
                          v-model="labels"
                          :value="item.key"
                        />
                        <icon
                          :name="`menu_inner_icon${index}`"
                          class="btn-menu-inner-icon"
                        />
                        <span>{{ item.label }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-marg"></div>
              </b-popover>
            </li>
            <li class="catalog-item-prop item-prop text-grey">
              Актуально на:
              {{
                apartment.updated != null ? apartment.updated.split(' ')[0] : ''
              }}
            </li>
            <li class="catalog-item-prop item-prop text-grey">
              {{ source_labels[apartment.source] }}
            </li>
          </ul>
        </div>

        <div class="mob-catalog-item d-flex d-lg-none" @click="showModal(apartment.id, $event)">
          <div class="mob-catalog-item-photo">
            <div
              class="hp-bg-container"
              :style="{
                backgroundImage: `url(${
                  apartment.images.length > 0
                    ? apartment.images[0].img_obj
                    : require('@/assets/images/no-image.gif')
                })`
              }"
            >
              <img
                v-if="apartment.status === 'created'"
                @click="e => e.stopPropagation()"
                src="@/assets/images/added-by-user.png"
                alt="No moderator2"
                class="img-no-moderator"
                title="Додано користувачем без перевірки модераторами"
                :id="`tooltip-target-added-by-user-mobile-${apartment.id}`"
              />
              <b-tooltip
                v-if="apartment.status === 'created'"
                :target="`tooltip-target-added-by-user-mobile-${apartment.id}`"
                triggers="hover"
                placement="bottom"
                >
                Додано користувачем без перевірки модераторами
              </b-tooltip>
              <img
                v-if="apartment.status === 'unmoderated'"
                @click="e => e.stopPropagation()"
                src="@/assets/images/no_moderator.png"
                alt="No moderator2"
                class="img-no-moderator"
                title="Об'єкт додано без модерації"
                :id="`tooltip-target-no-moderator-mobile-${apartment.id}`"
              />
              <b-tooltip v-if="apartment.status === 'unmoderated'" :target="`tooltip-target-no-moderator-mobile-${apartment.id}`" triggers="hover" placement="bottom">
                Об'єкт додано без модерації
              </b-tooltip>
              <img
                v-if="apartment.status === 'draft'"
                @click="e => e.stopPropagation()"
                src="@/assets/images/icon_for_my_object.png"
                alt="For personal use"
                class="img-no-moderator"
                title="Об'єкт додано для особистого користування"
                :id="`tooltip-target-icon-for-my-object-mobile-${apartment.id}`"
              />
              <b-tooltip :target="`tooltip-target-icon-for-my-object-mobile-${apartment.id}`" triggers="hover" placement="bottom">
                Об'єкт додано для особистого користування
              </b-tooltip>
              <!--  -->
              <img
                v-if="apartment.is_cashless_payment_available"
                @click="e => e.stopPropagation()"
                src="@/assets/images/cashless_payments.png"
                alt="No moderator"
                class="img-no-moderator"
                title="Власник готовий проводити угоду в безготівковій формі, що може підпадати під можливість розрахунку єОселя, 280 стаття та інші"
                :id="`tooltip-target-used-for-cashless-payments-mobile-${apartment.id}`"
              />
              <b-tooltip :target="`tooltip-target-used-for-cashless-payments-mobile-${apartment.id}`" triggers="hover" placement="bottom">
                Можлива безготівкова оплата, яка може бути використана для угод іпотеки, програми "єОселя", угоди за 280 постановою та інші операції.
              </b-tooltip>
              <!--  -->
              <img
                v-if="apartment.is_used_for_advertising"
                @click="e => e.stopPropagation()"
                src="@/assets/images/used_for_advertising.png"
                alt="No moderator"
                class="img-no-moderator"
                title="Фото об'єкта можна використовувати на рекламних майданчиках"
                :id="`tooltip-target-used-for-advertising-mobile-${apartment.id}`"
              />
              <b-tooltip v-if="apartment.is_used_for_advertising" :target="`tooltip-target-used-for-advertising-mobile-${apartment.id}`" triggers="hover" placement="bottom">
                Фото об'єкта можна використовувати на рекламних майданчиках
              </b-tooltip>
              <img
                v-if="apartment.is_seller_pays_the_commission"
                @click="e => e.stopPropagation()"
                src="@/assets/images/seller_pays_the_commission.png"
                alt="No moderator"
                class="img-no-moderator"
                title="Продавець оплачує комісію"
                :id="`tooltip-target-seller-pays-the-commission-mobile-${apartment.id}`"
              />
              <b-tooltip v-if="apartment.is_seller_pays_the_commission" :target="`tooltip-target-seller-pays-the-commission-mobile-${apartment.id}`" triggers="hover" placement="bottom">
                Продавець оплачує комісію
              </b-tooltip>
              <!-- Is children allowed -->
              <img
                v-if="apartment.is_children_allowed"
                @click="e => e.stopPropagation()"
                src="@/assets/images/is_children_allowed.png"
                alt="Allow children"
                class="img-no-moderator"
                title="Можна з дітьми"
                :id="`tooltip-target-is-children-allowed-mobile-${apartment.id}`"
              />
              <b-tooltip v-if="apartment.is_children_allowed" :target="`tooltip-target-is-children-allowed-mobile-${apartment.id}`" triggers="hover" placement="bottom">
                Можна з дітьми
              </b-tooltip>
              <!-- Is foreigners allowed -->
              <img
                v-if="apartment.is_foreigners_allowed"
                @click="e => e.stopPropagation()"
                src="@/assets/images/is_foreigners_allowed.png"
                alt="Allow foreigners"
                class="img-no-moderator"
                title="Беруть іноземців"
                :id="`tooltip-target-is-foreigners-allowed-mobile-${apartment.id}`"
              />
              <b-tooltip v-if="apartment.is_foreigners_allowed" :target="`tooltip-target-is-foreigners-allowed-mobile-${apartment.id}`" triggers="hover" placement="bottom">
                Беруть іноземців
              </b-tooltip>
              <!-- Is pets allowed -->
              <img
                v-if="apartment.is_pets_allowed"
                @click="e => e.stopPropagation()"
                src="@/assets/images/is_pets_allowed.png"
                alt="Allow pets"
                class="img-no-moderator"
                title="Можна з тваринами"
                :id="`tooltip-target-pets-allowed-mobile-${apartment.id}`"
              />
              <b-tooltip v-if="apartment.is_pets_allowed" :target="`tooltip-target-pets-allowed-mobile-${apartment.id}`" triggers="hover" placement="bottom">
                Можна з тваринами
              </b-tooltip>
            </div>
          </div>

          <div class="mob-catalog-info-container w-100 d-flex flex-column">
            <div class="mob-catalog-title">
              <span
                class="inner-text"
                v-html="getTitle()"
              ></span>
              <span class="moving-to-archive" v-if="apartment.is_archive === '1'">Переходить до архіву</span>
              <kebab-menu
                :altText="apartment.house_number"
                :popoverId="`mob-popover-target-${apartment.id}`"
                ref="kebabMenu"
              >
                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div
                      class="btn-group-toggle form-checkbox-b"
                      data-toggle="buttons"
                      v-for="(item, index) in labels_display"
                      :key="index"
                    >
                      <label class="kebab-menu-item">
                        <input
                          type="checkbox"
                          name="labels[]"
                          v-model="labels"
                          :value="item.key"
                        />
                        <span class="kebab-item-label">
                          <img :src="icons[index]" alt="action icon" class="kebab-item-icon">
                          {{ item.label }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </kebab-menu>
            </div>
            <ApartmentTable
              :apartment="apartment"
              :material_labels="material_labels"
              :house_type="house_type"
              :square="square"
              :repairItem="repairItem"
              :short_commerce_types="short_commerce_types"
              :commerce_types="commerce_types"
              :pricePerMeter="getPricePerMeter()"
            />
              <div class="text-center mt-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="243"
                  height="20"
                  viewBox="0 0 243 20"
                  style="fill: transparent"
                >
                  <path
                    d="M117.242 8.03372L122.764 12.5887L128.11 7.82894"
                    stroke="#00D2CD"
                    stroke-width="1.1646"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
          </div>
        </div>
      </div>


        <div class="product-modal item-modal" v-if="modal === apartment.id">
          <transition
            v-if="windowWidth > 991"
            name="fade-slide">
            <simplebar
              class="product-modal-inner scrollbar-external-y"
            >
              <catalog-card-info
                class="catalog-card-info"
                @show-lightbox="showGallery"
                @edit-object="$emit('edit-object', $event)"
                :edit="edit"
                :windowWidth="windowWidth"
                :apartment="apartment"
              >
              </catalog-card-info>
            </simplebar>
          </transition>
          <div class="mob-container product-modal-inner" v-else>
            <catalog-card-info-mobile
              @show-lightbox="showGallery"
              @edit-object="$emit('edit-object', $event)"
              class="catalog-card-info"
              :apartment="apartment"
              :windowWidth="windowWidth"
              :edit="edit"
            >
            </catalog-card-info-mobile>
          </div>
        </div>

      <vue-gallery-slideshow
        :images="imagesCollection"
        :index="index"
        @close="index = null"
      ></vue-gallery-slideshow>
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
import simplebar from 'simplebar-vue';
import LabelsMixin from '../../mixins/LabelsMixin';
import api from '../../api';
import { getHiddenPhone } from '@/helpers/formats';
import ApartmentTable from './ApartmentTable.vue';
import CatalogCardInfoMobile from './CatalogCardInfoMobile.vue';
import KebabMenu from '@/elements/kebab-menu'
import icon0 from '@/assets/object-actions/good.svg';
import icon1 from '@/assets/object-actions/bad.svg';
import icon2 from '@/assets/object-actions/smile.svg';
import icon3 from '@/assets/object-actions/force.svg';
import icon4 from '@/assets/object-actions/telescope.svg';
import icon5 from '@/assets/object-actions/plus.svg';
import icon6 from '@/assets/object-actions/minus.svg';
import icon7 from '@/assets/object-actions/sun.svg';
import icon8 from '@/assets/object-actions/phone.svg';
import icon9 from '@/assets/object-actions/stone.svg';
import UnionList from '@/elements/union-list/union-list.vue';


export default {
  name: 'CatalogCard',
  mixins: [LabelsMixin],
  props: {
    apartment: Object,
    edit: Boolean,
    page: {
      type: String,
      default: 'base'
    }
  },
  components: {
    UnionList,
    CatalogCardInfo: () => import('./CatalogCardInfo.vue'),
    CatalogCardInfoMobile,
    simplebar,
    VueGallerySlideshow,
    ApartmentTable,
    KebabMenu
  },
  data() {
    return {
      windowWidth: 0,
      modal: null,
      labels: this.apartment.labels,
      index: null,
      apartmentObject: this.apartment,
      square: '',
      icons: {
        0: icon0,
        1: icon1,
        2: icon2,
        3: icon3,
        4: icon4,
        5: icon5,
        6: icon6,
        7: icon7,
        8: icon8,
        9: icon9,
      }
    };
  },
  methods: {
    getPhone(phone){
      if(this.apartment.is_archive == '2' && this.apartment.source === 'owner'){
        return getHiddenPhone(phone);
      }
      return phone;
    },
    showModal(id, event) {
      if (this.$refs.kebabMenu && this.$refs.kebabMenu.$el.contains(event.target)) {
        return;
      }
      const idTooltipMobile = `tooltip-target-price-mobile-${id}`;
      const idTooltipMobileModerator = `tooltip-target-no-moderator-mobile-${id}`;
      const idTooltipMobileAdvertising = `tooltip-target-used-for-advertising-mobile-${id}`;
      const idTooltipMobileCommission = `tooltip-target-seller-pays-the-commission-mobile-${id}`;
      let path = event.path || (event.composedPath && event.composedPath());
      if(!path.some(({ id }) => id === idTooltipMobile || id === idTooltipMobileModerator || id === idTooltipMobileAdvertising || id === idTooltipMobileCommission) && this.modal !== id) {
          this.modal = id;
      } else {
        this.modal = null;
      }
    },
    showGallery(index) {
      this.index = index;
    },
    updateLabels() {
      const payload = {
        data: this.labels,
        params: { id: this.apartment.id }
      };

      api.updateApartmentLabels(payload);
    },
    getPrice() {
      const usd = this.apartment.business_type !== 'rent' ? '$' : '';
      const grn = this.apartment.business_type === 'rent' ? 'грн' : '';

      return usd + this.apartment.price + grn;
    },
    getPricePerMeter() {
      if(!this.apartment.square_common) {
        return 'Невідома площа для розрахунку'
      }
      const usd = this.apartment.business_type !== 'rent' ? '$' : '';
      const grn = this.apartment.business_type === 'rent' ? 'грн' : '';

      return 'Ціна за кв. метр ' + usd + (this.apartment.price / parseFloat(this.apartment.square_common)).toFixed(0) + grn;
   },
    getTitle() {
      let title = '';
      let showDistrict = true;
      let prefix = '';

      if (this.apartment.microdistrict && this.apartment.microdistrict.name) {
        let showFullMicrodistrict = false;

        if (this.apartment.microdistrict.name.search(/(верх)|(низ)/) !== -1) {
          showFullMicrodistrict = true;
        }

        title +=
          this.apartment.residential_complex && !showFullMicrodistrict
            ? `<span>${this.apartment.microdistrict.name.replace(/\((.+?)\)/g, '')} </span><br />`
            : `<span>${this.apartment.microdistrict.name} </span><br />`;

        showDistrict = false;
      }

      if (this.apartment.street && this.apartment.street.name) {
        title += this.apartment.residential_complex
          ? `${this.apartment.street.name.replace(/\((.+?)\)/g, '')} `
          : `${this.apartment.street.name}`;

        if (this.apartment.house_number) {
          title += this.apartment.house_number;
        }
        showDistrict = false;
      }

      if (
        showDistrict &&
        this.apartment.district &&
        this.apartment.city.is_city
      ) {
        title += this.apartment.district.name;
      }

      if (this.apartment.house_letter) {
        title += `${this.apartment.house_letter}`;
      }

      if (this.apartment.house_hull && this.apartment.house_hull.length > 0) {
        title += ` , корп. ${this.apartment.house_hull}`;
      }

      if (this.apartment.residential_complex) {
        prefix = `ЖК ${this.apartment.residential_complex.name} </br>`;
      }

      //  ЖК, ТЦ, ТРЦ, БЦ
      if (this.apartment.building) {
        prefix = `${this.apartment.building.name} </br>`;
      }

      title = `<span>${prefix}</span> ${title}`

      if (!this.apartment.city.is_city && this.apartment.district) {
        title += this.apartment.district.name;
      }
      return `<div class="mobile-card-title">${title}</div>`;
    }
  },
  watch: {
    labels() {
      this.updateLabels();
    }
  },
  computed: {
    imagesCollection() {
      const items = this.apartmentObject.images.map((a) => a.img_obj);
      return items;
    },
    repairItem() {
      const isItem = this.choises_label.find(
        (a) => a.label === this.apartmentObject.repair
      );
      if (isItem) return isItem.name;
      return undefined;
    },
    labels_display() {
      const labels_arr = this.apartment.labels ? this.apartment.labels : [];
      const returnLabels = [];
      for (const label in this.user_labels) {
        const t = {};
        t.label = this.user_labels[label];
        t.key = label;
        t.active = labels_arr.indexOf(label) > 0 ? 'active' : '';
        returnLabels.push(t);
      }
      return returnLabels;
    },
    images: () => {
      return [{ thumb: require('../../assets/images/no-image.png') }];
    },
    carText: () => [
      '<svg viewBox="0 0 22 22" class="owl-nav-left svg-icon svg-fill"><path d="M21 5.6L11 16.4 1 5.6h20z"></path></svg>',
      '<svg viewBox="0 0 22 22" class="owl-nav-right svg-icon svg-fill"><path d="M21 5.6L11 16.4 1 5.6h20z"></path></svg>'
    ]
  },
  created() {
    const squareMeter = 'м<sup>2</sup>';
    const map = [];

    if (parseFloat(this.apartment.square_common)) {
      map.push(parseFloat(this.apartment.square_common) + squareMeter);
    }

    if (parseFloat(this.apartment.square_living)) {
      map.push(parseFloat(this.apartment.square_living) + squareMeter);
    }

    if (parseFloat(this.apartment.square_kitchen)) {
      map.push(parseFloat(this.apartment.square_kitchen) + squareMeter);
    }

    if (parseFloat(this.apartment.square_area)) {
      map.push(`${parseFloat(this.apartment.square_area)}сот.`);
    }

    this.square = map.join(', ');
  },
  mounted() {
    this.windowWidth = window.innerWidth;
  }
};
</script>

<style lang="sass">
.old-price
  text-decoration: line-through
  margin-right: 5px
.catalog-item, .product-modal
    font-size: 14px
.btn-group-toggle:nth-child(2) .btn-menu-inner-icon
    transform: rotate(180deg)

.btn-group-toggle
    padding: 1px
    .btn-menu-inner-icon
        width: 12px
        height: 12px

.catalog-item-prop-label
    padding-top: 5px

.btn-outer-label
    padding: 2px
    .btn-menu-inner-icon
        max-height: 11px
        max-width: 11px

.copy_string_active
    color: green

.catalog-carousel
    height: 285px
    font-size: 14px
    .owl-carousel .owl-stage
        height: inherit
        display: flex!important

    .owl-carousel .owl-item
        height: inherit
        width: 100%

        img
            object-fit: contain
            height: 100%

        > div
            height: 285px

    +media-breakpoint-up(md)
        height: 500px

        .owl-carousel .owl-item
            > div
                height: 500px
.btn-red.btn-filter
    cursor: pointer
    :hover
        color: #fff

.img-no-moderator
    width: 30px !important
    height: 30px !important
    float: right
    margin-top: 3px
    margin-left: 3px

.mobile-card-title
  font-size: 15px
  font-weight: 600
  span
    font-size: 13px
    font-weight: 300

.moving-to-archive
  background: red
  padding: 0 4px
  border-radius: 4px

@media (max-width: 575px)
    .catalog-carousel
        height: 320px
@media (max-width: 991px)
  .card-block
    border: 1px solid rgba(46,41,62,0.32)
  .card-block--arhived
    background-color: rgb(114 140 166)
    .product-modal
      background-color: rgb(114 140 166)
@media (min-width: 991px)
  .card-block--arhived
    position: relative
    .catalog-item-prop, .catalog-card-info
      padding: 0 30px
      &:after
        content: ''
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: 10
        background-color: rgba(255, 255, 255, 0.2)
      .btn-filter
        z-index: 101
</style>
